
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {} 


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .galfoto{
        width:100%;
        height: 250px;
        object-fit: cover;
        margin-bottom: 1em;
        /*border:1px solid red;*/
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        
    }
    .gal-fot{
        width:100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 1em;
        z-index: 0;
    }
    .galfoto-contenedor{
        background: #eeeeee;
        width: 100%;
        height:100%;
        /*border:1px solid green;*/
    }
    .galfoto-etiqueta{
        background: #111111;
        width: 100%;
        height:0px;
        color:white;
        
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .galfoto-titulo{
        margin:0;
        opacity: 0;
    }
    .galfoto-texto{
        margin:0;
        font-size: 0.8em;
    }
    .galfoto:hover .galfoto-etiqueta{
        height:60px;
        transition: height 0.2s;
       
    }
    .galfoto:hover .galfoto-contenedor{
        height:220px;
        transition: height 0.2s;
       
    }
    .galfoto:hover .galfoto-titulo, .galfoto:hover .galfoto-texto{
        opacity: 1;
        transition: height 0.2s;
       
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .galfoto{
        width:48.5%;
        height:250px;
        object-fit: cover;
        margin-bottom: 1em;
        /*border:1px solid red;*/
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        
    }
    .gal-fot{
        width:100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 1em;
        z-index: 0;
    }
    .galfoto-contenedor{
        background: #eeeeee;
        width: 100%;
        height:100%;
        /*border:1px solid green;*/
    }
    .galfoto-etiqueta{
        background: #111111;
        width: 100%;
        height:0px;
        color:white;
        
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .galfoto-titulo{
        margin:0;
        opacity: 0;
    }
    .galfoto-texto{
        margin:0;
        font-size: 0.8em;
    }
    .galfoto:hover .galfoto-etiqueta{
        height:60px;
        transition: height 0.2s;
       
    }
    .galfoto:hover .galfoto-contenedor{
        height:220px;
        transition: height 0.2s;
       
    }
    .galfoto:hover .galfoto-titulo, .galfoto:hover .galfoto-texto{
        opacity: 1;
        transition: height 0.2s;
       
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .galfoto{
        width:32.5%;
        height: 280px;
        object-fit: cover;
        margin-bottom: 1em;
        /*border:1px solid red;*/
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        
    }
    .gal-fot{
        width:100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 1em;
        z-index: 0;
    }
    .galfoto-contenedor{
        background: #eeeeee;
        width: 100%;
        height:100%;
        /*border:1px solid green;*/
    }
    .galfoto-etiqueta{
        background: #111111;
        width: 100%;
        height:0px;
        color:white;
        
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .galfoto-titulo{
        margin:0;
        opacity: 0;
    }
    .galfoto-texto{
        margin:0;
        font-size: 0.8em;
    }
    .galfoto:hover .galfoto-etiqueta{
        height:60px;
        transition: height 0.2s;
       
    }
    .galfoto:hover .galfoto-contenedor{
        height:220px;
        transition: height 0.2s;
       
    }
    .galfoto:hover .galfoto-titulo, .galfoto:hover .galfoto-texto{
        opacity: 1;
        transition: height 0.2s;
       
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}