.aU-2{
    letter-spacing: 0.2em !important;
    font-weight: 300;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {} 


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px ) {
    .aboutUs{
        background: white;
        width:100%;
        height:1300px;
    
        display:flex;
        flex-direction: column; 
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1.5em;
    }
    
    .aboutUs-1{
        /*background: cyan;*/
        width:80%;
        height:450px;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    
        color:#222222;
    }
    .aU-1{
        color:gray;
    }
    .aU-2{
        font-size: 2.5em;
        margin-top:0;
        margin-bottom: 0.4em;
        text-align: justify;
    }
    .aU-3{
        color:gray;
        font-size: 0.9em;
        line-height: 1.5em;
    }
    .aboutUs-2{
        /*background: seashell;*/
        width:80%;
        height:800px;
        position: relative;
        left: 15px;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        border-left: 0.5px solid #bbbbbb;
    }
     .texto-central{
         background: #111111;
        color:white;
        position: relative;
        left: 15px;
    
        width:85%;
        top:0px;
        padding-left: 1em;
        border-radius: 0.3em;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        z-index: 1;
    
     }
     #texto-central-1{
         display:flex;
         position: relative;
         top: 25px;
     }
     #texto-central-2{
        display:flex;
        position: relative;
        top: 55px;
    }
    #texto-central-3{
        display:flex;
        position: relative;
        top: 85px;
    }
    #texto-central-4{
        display:flex;
        position: relative;
        top: 115px;
    }
    #texto-central-5{
        display:flex;
        position: relative;
        top: 145px;
    }
    #texto-central-6{
        display:flex;
        position: relative;
        top: 175px;
    }
    .tc-1{
        width: 80%;
        margin:0;
        margin-top: 1em;
        font-size: 0.9em;
        letter-spacing: 0.1em;
    }
    .tc-2{
        width: 90%;
        font-size: 0.75em;
    }
     .doble-circulo{
        position: absolute;
        z-index: 0;
        display:none;
     }
    .circulitos{
        all:unset;
        width: 30px;
        height: 30px;
        background: black;
        border-radius: 25px;
        position: relative;
        top:-1000px;
        z-index: 2;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .circulito-1{
        top:-530px;
        left:-145px;
        
    }
    .circulito-2{
        top:-437px;
        left:-145px;
    }
    .circulito-3{
        top: -340px;
        left:-145px;
    }
    .circulito-4{
        top:-247px;
        left:-145px;
    }
    .circulito-5{
        top: -155px;
        left:-145px;
    }
    .circulito-6{
        top: -63px;
        left:-145px;
    }
    .circ-icon{
        width: 70%;
        left: 70%;
        fill:white;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 990px) {
    .aboutUs{
        background: white;
        width:92%;
        height:1300px;
        position: relative;
        left:75px;
    
        display:flex;
        flex-direction: column; 
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1.5em;
    }
    
    .aboutUs-1{
        /*background: cyan;*/
        width:80%;
        height:450px;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    
        color:#222222;
    }
    .aU-1{
        color:gray;
    }
    .aU-2{
        font-size: 3.5em;
        margin-top:0;
        margin-bottom: 0.4em;
        text-align: justify;
    }
    .aU-3{
        color:gray;
        font-size: 0.9em;
        line-height: 1.5em;
    }
    .aboutUs-2{
        /*background: seashell;*/
        width:80%;
        height:800px;
        position: relative;
        left: 15px;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        border-left: 0.5px solid #bbbbbb;
    }
     .texto-central{
         background: #111111;
        color:white;
        position: relative;
        left: 15px;
    
        width:85%;
        top:0px;
        padding-left: 1em;
        border-radius: 0.3em;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        z-index: 1;
    
     }
     #texto-central-1{
         display:flex;
         position: relative;
         top: 25px;
     }
     #texto-central-2{
        display:flex;
        position: relative;
        top: 55px;
    }
    #texto-central-3{
        display:flex;
        position: relative;
        top: 85px;
    }
    #texto-central-4{
        display:flex;
        position: relative;
        top: 115px;
    }
    #texto-central-5{
        display:flex;
        position: relative;
        top: 145px;
    }
    #texto-central-6{
        display:flex;
        position: relative;
        top: 175px;
    }
    .tc-1{
        width: 80%;
        margin:0;
        margin-top: 1em;
        font-size: 0.9em;
        letter-spacing: 0.1em;
    }
    .tc-2{
        width: 90%;
        font-size: 0.75em;
    }
     .doble-circulo{
        position: absolute;
        z-index: 0;
        display:none;
     }
    .circulitos{
        all:unset;
        width: 30px;
        height: 30px;
        background: black;
        border-radius: 25px;
        position: relative;
        top:-1000px;
        z-index: 2;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .circulito-1{
        top:-440px;
        left:-283px;
        
    }
    .circulito-2{
        top:-365px;
        left:-283px;
    }
    .circulito-3{
        top: -285px;
        left:-283px;
    }
    .circulito-4{
        top:-205px;
        left:-283px;
    }
    .circulito-5{
        top: -125px;
        left:-283px;
    }
    .circulito-6{
        top: -45px;
        left:-283px;
    }
    .circ-icon{
        width: 70%;
        left: 70%;
        fill:white;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .aboutUs{
        background: white;
        width:100%;
        height:650px;
    
        display:flex;
        flex-direction: row; 
        justify-content: space-around;
        align-items: center;
    }
    
    .aboutUs-1{
        /*background: cyan;*/
        width:40%;
        height:100%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    
        color:#222222;
    }
    .aU-1{
        color:gray;
    }
    .aU-2{
        font-size: 3.5em;
        margin-top:0;
        margin-bottom: 0.4em;
    }
    .aU-3{
        color:gray;
    }
    .aboutUs-2{
        /*background: seashell;*/
        width:35%;
        height:100%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
     .texto-central{
        color:white;
        position: relative;
    
        width:40%;
        top:160px;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
    
     }
     #texto-central-1{
         display:flex;
     }
     #texto-central-2{
        display:none;
    }
    #texto-central-3{
        display:none;
    }
    #texto-central-4{
        display:none;
    }
    #texto-central-5{
        display:none;
    }
    #texto-central-6{
        display:none;
    }
    .tc-1{
        width: 90%;
        margin:0;
        text-align:center;
    }
    .tc-2{
        width: 75%;
        text-align:center;
        font-size: 0.7em;
    }
     .doble-circulo{
        position: absolute;
        z-index: 0;
     }
    .circulitos{
        all:unset;
        width: 50px;
        height: 50px;
        background: black;
        border-radius: 25px;
        position: relative;
        top:-300px;
        z-index: 2;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .circulito-1{
        top:-160px;
        background: MidnightBlue;
    }
    .circulito-2{
        top:-100px;
        left:190px;
    }
    .circulito-3{
        top: 80px;
        left:190px;
    }
    .circulito-4{
        top:130px;
    }
    .circulito-5{
        top: -20px;
        left:-190px;
    }
    .circulito-6{
        top: -300px;
        left:-190px;
    }
    .circ-icon{
        width: 50%;
        left: 50%;
        fill:white;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}