

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .navigation{
        background: white;
        width:290px;
        height:250px;
        list-style: none;
        position: absolute;
        top:40px;
        left:16px;
    
    
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    
        color:gray;
        /*background: yellow;*/
        opacity: 0;
    }
    .agregar-flex{
        display: flex;
    }
    .navigation li {
        width:328px;
        height:20%;
        border: 0.5px solid #e8e8e8;
        position: relative;
        left:-40px;
    
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .navigation a {
        width: 100%;
        height: 20%;
        text-decoration: none;
        color:#777777;
        font-size: 0.8em;
        margin: 0 1.5em 0 1.5em;
    }
    .navigation a:hover {
        color:#ffffff;
        transition: 1s color;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width:990px) {
    .navigation{
        background: white;
        width:290px;
        height:250px;
        list-style: none;
        position: absolute;
        top:40px;
        left:16px;
    
    
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    
        color:gray;
        /*background: yellow;*/
        opacity: 0;
    }
    .agregar-flex{
        display: flex;
    }
    .navigation li {
        width:328px;
        height:20%;
        border: 0.5px solid #e8e8e8;
        position: relative;
        left:-40px;
    
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .navigation a {
        width: 100%;
        height: 20%;
        text-decoration: none;
        color:#777777;
        font-size: 0.8em;
        margin: 0 1.5em 0 1.5em;
    }
    .navigation a:hover {
        color:#ffffff;
        transition: 1s color;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .navigation{
        background: none;
        width:290px;
        height:100%;
        list-style: none;
        position: relative;
        left:600px;
    
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
    
        color:gray;
        /*background: yellow;*/
    }
    .navigation li {
        width:328px;
        height:100%;
    
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .navigation a {
        width: 100%;
        height: 20%;
        text-decoration: none;
        color:#777777;
        font-size: 1em;
        margin: 0 1.5em 0 1.5em;
    }
    .navigation a:hover {
        color:#ffffff;
        transition: 1s color;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}