
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {} 


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .sobreMi_1{
        width: 100%;
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .sm-1{
        width:80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    .sm-1-titulo{
        width:85%;
        color:#222222;
    }
    .sm-1-texto{
        width:85%;
        color:gray;
    }
    .sm-2{
        width:100%;
        height: 300px;
        background: white;
        margin-top: 50px;
    
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .sm-2-contacto{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2em;
    
        background: white;
        position: relative;
        top:20px;
    }
    .sm-2 a{
        color:black;
        text-decoration: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .sobreMi_1{
        width: 100%;
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .sm-1{
        width:80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    .sm-1-titulo{
        width:85%;
        color:#222222;
    }
    .sm-1-texto{
        width:85%;
        color:gray;
    }
    .sm-2{
        width:100%;
        height: 300px;
        background: white;
        margin-top: 50px;
    
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .sm-2-contacto{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2em;
    
        background: white;
        position: relative;
        top:20px;
    }
    .sm-2 a{
        color:black;
        text-decoration: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .sobreMi_1{
        height: 370px;
        display: flex;
        flex-direction: row;
    }
    .sm-1{
        width:40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    .sm-1-titulo{
        width:85%;
        color:#222222;
    }
    .sm-1-texto{
        width:85%;
        color:gray;
    }
    .sm-2{
        width:60%;
        height: 100%;
        background: white;
    
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .sm-2-contacto{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2em;
    
        background: white;
        position: relative;
        top:20px;
    }
    .sm-2 a{
        color:black;
        text-decoration: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}