body{
  margin:0;
  font-family: 'Helvetica Neue','Arial','sans-serif';
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .container{
        display:grid;
        grid-template-rows: 60px
                            517px
                            6200px
                            1600px
                            500px;
            grid-template-columns: 100%;
            grid-template-areas: 
            "header"
            "banner"
            "main"
            "aside"
            "footer";
        }
      header{
        grid-area: header;
        background:none;
      
            display:flex;
            justify-content: flex-start;
            align-items: center;
      
            position: fixed;
            top:-80px;
            width: 100%;
            height: 50px;
            z-index: 5;
      }
      .header-animacion-arriba{
          animation-name: posicion-top-arriba;
          animation-duration: 0.25s;
      }
      @keyframes posicion-top-arriba {
          from {top:-30px;}
          to {top:0px;} 
      }
      
      .header-animacion-abajo{
          animation-name: posicion-top-abajo;
          animation-duration: 0.25s;
      }
      @keyframes posicion-top-abajo {
          from {top:0px;}
          to {top:-30px;} 
      }
      .btn-menu{
          background: none;
         border:none;
         position: relative;
         left: 110px;

          display: flex;
      }
      .btn-menu:active ~ .navigation {
          opacity: 1;
      }
        
        .banner{
            grid-area: banner;

            border-bottom: 2px solid crimson;
            }
        main{
            grid-area: main;
            background:white;
      
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            
        }
        aside{
            grid-area: aside;
            background:white;
      
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        footer{
            grid-area: footer;
            background:#111111;
        }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width:990px)   {
    .container{
        display:grid;
        grid-template-rows: 60px
                            517px
                            5800px
                            1600px
                            500px;
            grid-template-columns: 100%;
            grid-template-areas: 
            "header"
            "banner"
            "main"
            "aside"
            "footer";
        }
      header{
        grid-area: header;
        background:none;
      
            display:flex;
            justify-content: flex-start;
            align-items: center;
      
            position: fixed;
            top:-80px;
            width: 100%;
            height: 50px;
            z-index: 5;
      }
      .header-animacion-arriba{
          animation-name: posicion-top-arriba;
          animation-duration: 0.25s;
      }
      @keyframes posicion-top-arriba {
          from {top:-30px;}
          to {top:0px;} 
      }
      
      .header-animacion-abajo{
          animation-name: posicion-top-abajo;
          animation-duration: 0.25s;
      }
      @keyframes posicion-top-abajo {
          from {top:0px;}
          to {top:-30px;} 
      }
      .btn-menu{
          background: none;
         border:none;
         position: relative;
         left: 110px;

          display: flex;
      }
      .btn-menu:active ~ .navigation {
          opacity: 1;
      }
        
        .banner{
            grid-area: banner;

            border-bottom: 2px solid crimson;
            }
        main{
            grid-area: main;
            background:white;
      
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        aside{
            grid-area: aside;
            background:white;
      
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        footer{
            grid-area: footer;
            background:#111111;
        }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
  .container{
  display:grid;
  grid-template-rows: 60px
                      553px
                      3700px
                      1100px
                      500px;
      grid-template-columns: 100%;
      grid-template-areas: 
      "header"
      "banner"
      "main"
      "aside"
      "footer";
  }
header{
  grid-area: header;
  background:none;

      display:flex;
      justify-content: flex-start;
      align-items: center;

      position: fixed;
      top:-80px;
      width: 100%;
      z-index: 5;
}
.header-animacion-abajo{
    animation-name: posicion-top-abajo;
    animation-duration: 0.25s;
}
@keyframes posicion-top-abajo {
    from {top:-80px;}
    to {top:0px;} 
}

.header-animacion-arriba{
    animation-name: posicion-top-arriba;
    animation-duration: 0.25s;
}
@keyframes posicion-top-arriba {
    from {top:0px;}
    to {top:-80px;} 
}
.btn-menu{
    display: none;
}

  
  .banner{
      grid-area: banner;
      border-bottom: 3px solid crimson;
      }
  main{
      grid-area: main;
      background:white;

      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      
  }
  aside{
      grid-area: aside;
      background:white;

      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
  }
  footer{
      grid-area: footer;
      background:#111111;
  }

}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}